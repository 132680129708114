var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "task-container",
    attrs: {
      "name": "task"
    }
  }, [_c('TaskColor', {
    attrs: {
      "hover-active": _vm.hoverActive,
      "color": _vm.taskType.color
    },
    on: {
      "click": _vm.emitOpenTaskWidget,
      "mouseover": function ($event) {
        _vm.hoverActive = true;
      },
      "mouseleave": function ($event) {
        _vm.hoverActive = false;
      }
    }
  }), _c('div', {
    staticClass: "task-container-content",
    class: {
      'task-container-no-button': _vm.task.category !== 'task' && _vm.task.category !== 'open_house' && !(_vm.task.category === 'event' && _vm.task.type === 'open_house' && _vm.task.content === 'OpenHouse')
    },
    style: _vm.style,
    on: {
      "click": _vm.emitOpenTaskWidget,
      "mouseover": function ($event) {
        _vm.hoverActive = true;
      },
      "mouseleave": function ($event) {
        _vm.hoverActive = false;
      }
    }
  }, [_vm.taskIcon ? _c('img', {
    attrs: {
      "height": "20",
      "src": require(`@/assets/images/${_vm.taskIcon}`),
      "alt": ""
    }
  }) : _vm._e(), _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "gap": "6px",
      "width": "100%",
      "overflow": "hidden",
      "text-overflow": "ellipsis"
    }
  }, [_c('div', {
    staticClass: "content-normal"
  }, [_vm.task.category !== 'open_house' ? [_c('div', {
    staticStyle: {
      "overflow": "hidden",
      "white-space": "nowrap",
      "text-overflow": "ellipsis"
    }
  }, [_vm._v(" " + _vm._s(_vm.task.content) + " ")])] : _vm._e(), _vm.task.category === 'open_house' ? [_c('div', {
    staticStyle: {
      "overflow": "hidden",
      "white-space": "nowrap",
      "text-overflow": "ellipsis"
    }
  }, [_vm._v(" " + _vm._s(_vm.task.linkedProject.name) + " ")])] : _vm._e()], 2), _vm.task.category === 'open_house' ? _c('div', {
    staticStyle: {
      "display": "flex",
      "gap": "4px"
    }
  }, [_c('div', {
    staticClass: "task-open-house-label content-small",
    style: {
      backgroundColor: _vm.taskType.color
    }
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.taskType.translation)) + " ")]), _vm.task.numberOfParticipants ? _c('div', {
    staticClass: "task-open-house-participants-label content-small"
  }, [_vm._v(" " + _vm._s(_vm.task.numberOfParticipants + " " + _vm.$t("Participants")) + " ")]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "task-open-house-date-comment-row"
  }, [_vm.task.dates && _vm.task.dates.endDate ? _c('div', {
    staticClass: "label10",
    class: {
      'green-date-time': !_vm.endDatePassed,
      'red-date-time': _vm.endDatePassed && !_vm.task.numberOfParticipants,
      'grey-date-time': _vm.endDatePassed && _vm.task.numberOfParticipants
    }
  }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.task.dates)) + " ")]) : _vm._e(), _vm.task.comment ? _c('img', {
    staticClass: "task-open-house-comment-icon",
    attrs: {
      "src": require('@/assets/images/chat-bubble.svg'),
      "alt": ""
    }
  }) : _vm._e()])])]), _vm.task.category === 'task' || _vm.task.category === 'open_house' || _vm.task.category === 'event' && _vm.task.type === 'open_house' && _vm.task.content === 'OpenHouse' ? _c('TaskButton', {
    attrs: {
      "is-active": _vm.task.isCompleted,
      "task": _vm.task,
      "type": _vm.taskType,
      "category": _vm.task.category,
      "hover-active": _vm.hoverActive
    },
    on: {
      "click": _vm.taskEvent
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }