<template>
  <div name="task" class="task-container">
    <TaskColor
      @click="emitOpenTaskWidget"
      @mouseover="hoverActive = true"
      @mouseleave="hoverActive = false"
      :hover-active="hoverActive"
      :color="taskType.color"
    />
    <div
      @click="emitOpenTaskWidget"
      @mouseover="hoverActive = true"
      @mouseleave="hoverActive = false"
      class="task-container-content"
      :class="{
        'task-container-no-button':
          task.category !== 'task' &&
          task.category !== 'open_house' &&
          !(
            task.category === 'event' &&
            task.type === 'open_house' &&
            task.content === 'OpenHouse'
          ),
      }"
      :style="style"
    >
      <img
        v-if="taskIcon"
        height="20"
        :src="require(`@/assets/images/${taskIcon}`)"
        alt=""
      />
      <div
        style="
          display: flex;
          flex-direction: column;
          gap: 6px;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        "
      >
        <div class="content-normal">
          <template v-if="task.category !== 'open_house'">
            <div
              style="
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              "
            >
              {{ task.content }}
            </div></template
          >
          <template v-if="task.category === 'open_house'">
            <div
              style="
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              "
            >
              {{ task.linkedProject.name }}
            </div>
          </template>
        </div>
        <div
          style="display: flex; gap: 4px"
          v-if="task.category === 'open_house'"
        >
          <div
            class="task-open-house-label content-small"
            :style="{ backgroundColor: taskType.color }"
          >
            {{ $t(taskType.translation) }}
          </div>
          <div
            v-if="task.numberOfParticipants"
            class="task-open-house-participants-label content-small"
          >
            {{ task.numberOfParticipants + " " + $t("Participants") }}
          </div>
        </div>
        <div class="task-open-house-date-comment-row">
          <div
            :class="{
              'green-date-time': !endDatePassed,
              'red-date-time': endDatePassed && !task.numberOfParticipants,
              'grey-date-time': endDatePassed && task.numberOfParticipants,
            }"
            v-if="task.dates && task.dates.endDate"
            class="label10"
          >
            {{ formatDate(task.dates) }}
          </div>
          <img
              class="task-open-house-comment-icon"
              v-if="task.comment"
              :src="require('@/assets/images/chat-bubble.svg')"
              alt=""
          >
        </div>
      </div>
    </div>
    <TaskButton
      @click="taskEvent"
      :is-active="task.isCompleted"
      v-if="
        task.category === 'task' ||
        task.category === 'open_house' ||
        (task.category === 'event' &&
          task.type === 'open_house' &&
          task.content === 'OpenHouse')
      "
      :task="task"
      :type="taskType"
      :category="task.category"
      :hover-active="hoverActive"
    />
  </div>
</template>

<script>
import TaskButton from "@/components/CalendarView/TaskButton";
import TaskColor from "@/components/CalendarView/TaskColor";
import taskTypes from "@/components/CalendarView/taskTypes";
import moment from "moment";
import axios from "axios";

export default {
  name: "Task",
  components: { TaskColor, TaskButton },
  props: ["task", "endDatePassed"],
  data() {
    return {
      taskType: null,
      hoverActive: false,
      taskIcon: "",
      style: {
        borderTop: "1px solid #E6E8EC",
        borderBottom: "1px solid #E6E8EC",
        padding: "8px 16px 8px 8px",
      },
    };
  },
  created() {
    this.assignColor(this.task);
    this.checkBorder(false);
    this.getIconString(this.task);
  },
  watch: {
    task() {
      this.assignColor(this.task);
      this.getIconString(this.task);
    },
    hoverActive(value) {
      if (this.hoverActive) {
        this.style.borderTop = "2px solid " + this.taskType.color;
        this.style.borderBottom = "2px solid " + this.taskType.color;
        this.style.padding = "7px 16px 7px 8px";
        this.checkBorder(value);
      } else {
        this.style.borderTop = "1px solid #E6E8EC";
        this.style.borderBottom = "1px solid #E6E8EC";
        this.style.padding = "8px 16px 8px 8px";
        this.checkBorder(value);
      }
    },
  },
  methods: {
    getIconString(task) {
      if (!task.icon) {
        if (task.category === "resource") {
          this.taskIcon = "pencil_20_20.svg";
        } else {
          if (task.category === "event" || task.category === "open_house") {
            this.taskIcon = "calendar_20_20.svg";
          } else {
            this.taskIcon = "flag_20_20.svg";
          }
        }
        return;
      }
      switch (task.icon) {
        case "task":
          this.taskIcon = "flag_20_20.svg";
          break;
        case "call":
          this.taskIcon = "call.svg";
          break;
        case "email":
          this.taskIcon = "email_24_24.svg";
          break;
        case "invoice":
          this.taskIcon = "invoice.svg";
          break;
      }
    },
    async taskEvent() {
      if (this.task.category === "task") {
        this.task.isCompleted = !this.task.isCompleted;
        this.task.completedAt = this.task.isCompleted ? moment().format() : "";
        await axios.patch(`/api/task/${this.task._id}`, this.task);
        this.$emit("taskUpdated");
      } else {
        this.addParticipants();
      }
    },
    addParticipants() {
      this.$emit("addParticipants", this.task);
    },
    assignColor() {
      this.taskType = taskTypes.find(
        (taskType) => taskType.value === this.task.type
      );
    },
    formatDate(date) {
      if (
        !date.startTime &&
        !date.endTime &&
        !date.startDate &&
        !date.endDate
      ) {
        return;
      }
      if (date.startTime && date.endTime && date.startDate && date.endDate) {
        const formatedEndDate = moment(date.endDate).format("DD.MM.YYYY");
        const times = date.startTime + " - " + date.endTime;
        return formatedEndDate + " • " + times;
      }
      if (date.endTime && date.endDate) {
        return moment(date.endDate).format("DD.MM.YYYY • HH:mm");
      }
      if (date.endDate) {
        return moment(date.endDate).format("DD.MM.YYYY");
      }
    },
    checkBorder(hoverActive) {
      if (
        this.task.category === "task" ||
        this.task.category === "open_house" ||
        (this.task.category === "event" &&
          this.task.type === "open_house" &&
          this.task.content === "OpenHouse")
      ) {
        return;
      }
      if (hoverActive) {
        this.style.borderRight = "2px solid " + this.taskType.color;
      } else {
        this.style.borderRight = "1px solid #E6E8EC";
      }
    },
    emitOpenTaskWidget() {
      this.$emit("openTaskViewWidget", this.task);
    },
  },
};
</script>

<style scoped>
.task-container {
  min-height: 54px;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  cursor: pointer;
}

.task-container-content {
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.task-container-no-button {
  border-radius: 0 8px 8px 0;
}

.green-date-time {
  color: #0aaf60;
}

.red-date-time {
  color: #ff1e24;
}

.grey-date-time {
  color: #939597;
}
.task-open-house-label {
  padding: 2px 4px;
  font-weight: bold;
  color: white;
  border-radius: 4px;
  height: 20px;
  display: flex;
  justify-content: center;
}
.task-open-house-date-comment-row {
  display: flex;
  justify-content: space-between
}
.task-open-house-comment-icon {
  width: 16px;
  height: 16px;
}
.task-open-house-participants-label {
  padding: 2px 4px;
  font-weight: bold;
  color: #7200e2;
  border-radius: 4px;
  height: 20px;
  display: flex;
  justify-content: center;
  background-color: #f7eeff;
}
</style>
